import { createAsync } from '@solidjs/router';
import { IconArrowsReload01, IconCreditCard01, IconGlobe, IconStar, IconUserSquare } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { Show, Suspense } from 'solid-js';
import { requireLoggedIn } from '../providers/user';
import { SidebarLayout } from '../layouts/sidebar';
import type { ComponentProps } from 'solid-js';
import type { NavigationMenuItem } from '@troon/ui';
import type { RouteSectionProps } from '@solidjs/router';

const userNav: Array<ComponentProps<typeof NavigationMenuItem>> = [
	{ icon: IconStar, href: '/account', children: 'Account' },
	{ icon: IconGlobe, href: '/account/activity', children: 'Activity' },
	{ icon: IconArrowsReload01, href: '/account/access', children: 'Troon Access' },
	{ icon: IconUserSquare, href: '/account/profile', children: 'Profile' },
	{ icon: IconCreditCard01, href: '/account/payment-methods', children: 'Payment methods' },
];

export default function Account(props: RouteSectionProps) {
	const user = createAsync(() => requireLoggedIn(), { deferStream: true });

	return (
		<>
			<Title>My account | Troon</Title>
			<Suspense>
				<Show when={user()}>
					<SidebarLayout
						sidebar={
							user()?.activeTroonCardSubscription ? userNav : userNav.filter((item) => !item.href.endsWith('/access'))
						}
					>
						<div class="flex flex-col gap-4 md:gap-8">{props.children}</div>
					</SidebarLayout>
				</Show>
			</Suspense>
		</>
	);
}
